<template>
  <section>
    <b-card no-body>
      <b-card-header class="mb-1 justify-content-start">
        <b-img
          v-if="event.logo_url"
          id="logo"
          class="mr-2"
          left
          rounded
          :src="event.logo_url"
        />

        <div class="ml-1">
          <b-card-title class="event-name mb-1">
            {{ event.name }}
          </b-card-title>
          <div class="d-flex flex-wrap">
            <b-button
              variant="outline-primary"
              target="_blank"
              size="sm"
              :href="websiteUrl"
            >
              <feather-icon
                icon="ExternalLinkIcon"
                class="mr-25"
              />
              View Website
            </b-button>
          </div>
        </div>
      </b-card-header>

      <b-card-body class="p-0">
        <nav-items
          tabs
          class="mb-0 nav-nowrap"
          :nav-items="navItems"
          :nav-item="{
            'link-classes': 'pb-1',
            style: 'white-space: nowrap'
          }"
        />
      </b-card-body>
    </b-card>

    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <router-view />
    </transition>
  </section>
</template>

<script>
import {
  BCard, BCardTitle, BButton, BImg, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import NavItems from '@/components/NavItems.vue'

import useFlash from '@/composables/useFlash'
import { mapMutations, mapState } from 'vuex'
import axios from '@/libs/axios'
import store from '@/store'
import routes from '@/router/league'
import useAppConfig from '@core/app-config/useAppConfig'

const { flashError } = useFlash()

export default {
  name: 'Event',
  components: {
    BCard,
    BCardTitle,
    BButton,
    BCardHeader,
    BCardBody,
    BImg,
    NavItems,
  },
  setup() {
    const {
      routerTransition, navbarBackgroundColor,
    } = useAppConfig()

    return {
      // App Config
      routerTransition,
      navbarBackgroundColor,
    }
  },
  computed: {
    ...mapState('event', ['event']),
    websiteUrl() {
      return `${process.env.VUE_APP_WEBSITE_URL}/${this.event.sport}/${this.$store.getters['account/organization'].slug}/${this.event.id}`
    },
    navItems() {
      return routes.find(route => route.path === '/leagues' && route.children)
        .children.filter(route => route.navTitle)
        .map(route => {
          const r = route

          if (route.name === 'event.manage' && !store.state.event.seasons.seasons.length) {
            r.disabled = true
          }

          return r
        })
    },
  },
  beforeDestroy() {
    // Reset the event store and modules back to default state.
    store.dispatch('event/resetAllModules')
  },
  methods: {
    ...mapMutations('app', ['UPDATE_PAGE_TITLE', 'UPDATE_PAGE_IMAGE']),
    ...mapMutations('event', ['UPDATE_EVENT']),
  },
  async beforeRouteEnter(to, from, next) {
    if (!to.params.event) {
      next()
      return
    }

    try {
      await store.dispatch('event/seasons/getSeasons', { event: to.params.event })

      const response = await axios.get(`events/${to.params.event}`)
      const { data } = response.data

      store.commit('event/UPDATE_EVENT', data)
      store.commit('app/sport/UPDATE_SPORT', data.sport)

      next()
    } catch (error) {
      flashError(error)
      next({ name: 'events' })
    }
  },
  beforeRouteUpdate(to, from, next) {
    // TODO: figure out how to redirect unauthorized users.
    next()
  },
}
</script>

<style lang="scss" scoped>
#logo {
  max-width: 110px;
  max-height: 60px;
  @media (min-width: 576px) {
    max-width: 200px;
  }
}
.event-name {
  font-size: 1.4rem;
  @media (min-width: 576px) {
    font-size: 1.8rem;
  }
}
</style>
