<template>
  <b-nav v-bind="$attrs">
    <b-nav-item
      v-for="navItem in navItems"
      :key="navItem.navTitle"
      :to="{ name: navItem.name }"
      :disabled="navItem.disabled || false"
      v-bind="$attrs['nav-item']"
      active-class="active"
    >
      <font-awesome-icon
        fixed-width
        size="lg"
        :icon="`fa-light ${navItem.icon}`"
        v-bind="$attrs.icon"
      />
      {{ navItem.navTitle }}
    </b-nav-item>
  </b-nav>
</template>

<script>
import { BNav, BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNav,
    BNavItem,
  },
  props: {
    navItems: {
      type: Array,
      default: () => ([]),
    },
    topLevelRoute: {
      type: String,
      default: null,
    },
  },
  watch: {
    $route(to, from) {
      this.handleRoute(to, from)
    },
  },
  created() {
    this.handleRoute(this.$route, this.$route)
  },
  methods: {
    handleRoute(to, from) {
      // If the route name is top-level route, redirect the user to the first route.
      if (to.name === this.topLevelRoute) {
        const firstRouteName = this.navItems[0].name

        // Don't show the progress bar if the user clicks on the top-level route, and they are already on the first tab.
        if (from.name === firstRouteName) {
          this.$Progress.finish()
        }

        this.$router.replace({ name: firstRouteName, params: to.params })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  //.nav-item {
  //  margin-right: 5px
  //}
  //.nav-pills .nav-link {
  //  background-color: var(--primary);
  //  border: 3px solid transparent;
  //}
</style>
